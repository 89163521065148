<template>
  <home-layout id="home">
    <v-container class="tw-h-screen d-flex flex-row justify-center align-center">
      <v-row style="height: 32rem">
        <v-col cols="12" lg="8" md="8" sm="12" class="text-left">
          <v-img src="@/assets/images/logoSD.png" class="tw-w-52"/>
          <h1 class="tw-mt-24 tw-text-white">Estamos trabajando <br> en nuestro nuevo <br> <h1 class="tw-text-secondary-1">espacio digital.</h1></h1>
          <!--<div style="font-style: italic;">
            <h3 class="tw-text-white tw-mt-5 tw-inline-block">nuevo </h3>
            <h3 class="tw-text-secondary-1 tw-inline-block tw-ml-2"> espacio digital.</h3>
          </div>-->
          <h5 class="tw-text-white tw-mt-28 font-weight-black">¿Necesitas ayuda?<br>
            Contáctame en <span class="tw-text-secondary-1 font-weight-black">info@soydigital.tech</span></h5>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="12" class="flex-social tw-relative">
          <div class="border-solid"></div>
          <v-btn fab class="tw-bg-transparent btn-social" max-height="36" max-width="36" @click="goToLink('https://www.facebook.com/soydigitaltech')">
            <v-img src="@/assets/icon/Facebook.svg" alt="" max-height="36" max-width="36" />
          </v-btn>
          <v-btn fab class="tw-bg-transparent btn-social" max-height="36" max-width="36" @click="goToLink('https://www.instagram.com/soydigitaltech/')">
            <v-img src="@/assets/icon/Instagram.svg" alt="" max-height="36" max-width="36" />
          </v-btn>
          <v-btn fab class="tw-bg-transparent btn-social" max-height="36" max-width="36" @click="goToLink('https://www.linkedin.com/company/soydigitaltech/')">
            <v-img src="@/assets/icon/Linkedin.svg" alt="" max-height="36" max-width="36" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </home-layout>
</template>
<script>
import HomeLayout from '../../templates/home-layout/HomeLayout'
export default {
  name: 'HomeComponent',
  components: {
    HomeLayout
  },
  data: () => ({
  }),
  methods: {
    goToLink (link) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
}
</script>
