<template>
  <home-component/>
</template>

<script>
import HomeComponent from '../modules/home/HomeComponent'

export default {
  name: 'HomeView',
  components: {
    HomeComponent
  }
}
</script>
